<template>
<div class="input-group mb-3 search-bar">

  <input type="text" v-model="search_text" class="form-control form-control-alternative" style="height: 60px" aria-label="Text input with dropdown button">
  <div class="input-group-prepend">
    <button class="btn dropdown-btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <small v-if="$i18n.locale==='kin'">{{selected_category.kin_name}}</small>
      <small v-else>{{selected_category.name}}</small>
    </button>
    <div :class="$displayManager.isMobile()?'dropdown-menu menu-style category-mobile-display':'dropdown-menu menu-style'"  style="z-index: 2000" >
      <a v-for="(category, index) in all_categories" :key="index" :class="$displayManager.isMobile()?'dropdown-item font-size-mobile-display':'dropdown-item'" @click="selectCategory(category)" style="z-index: 2000">
        <small v-if="$i18n.locale==='kin'">{{category.kin_name}}</small>
        <small v-else>{{category.name}}</small>
      </a>
    </div>
  </div>
  <div class="input-group-prepend">
    <button class="btn custom-search-button" type="button" @click="triggerSearch()">
      <span class="fa fa-search"></span>
    </button>
  </div>
</div>
</template>

<script>
export default {
   name: "HomeSearchField",
   props:{
     categories:{
       type:Array,
       required: true,
     }
   },
   data(){
     return{
        selected_category:{
          kin_name:"Ibyiciro byose",
          name: "All categories",
          uuid:'ALL',
          icon:'fa fa-bars',
          color:'#000',
        },
        search_text:'',
        search_data:{
        },
     }
   } ,
  computed:{
     all_categories:function () {
       let temp=this.categories
       temp.unshift({
         kin_name:"Ibyiciro byose",
         name: "All categories",
         uuid:'ALL',
         icon:'fa fa-bars',
         color:'#000',
       })
       return temp
     }
  },
   methods:{
     
     selectCategory:function (evt) {
       this.selected_category=evt
       this.$emit('category-click', evt)
     },
   
     triggerSearch:function () {
       this.$emit(
           'onsearch',
           {
             search_text:this.search_text,
             category:this.selected_category
           }
       )
     }
   },

}
</script>

<style scoped>
.search-bar{
  border:  solid rgb(230, 230, 230) 1px;
}
.dropdown-btn{
  border:  solid rgb(252, 252, 252) 2px;
}
.dropdown-btn:hover{
  border:  solid rgb(252, 252, 252) 2px;
}

.custom-search-button{

  border:  solid rgb(252, 252, 252) 2px;
  color: rgb(146, 146, 150);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 2px;
}
.menu-style{
  height: 70vh;
  overflow: scroll;
}

 .category-mobile-display{
   width: 1000%;
 }

 .font-size-mobile-display{
  font-size: 20px;
 }

</style>