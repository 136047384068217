<template>
  <div >
    <div class="roundend d-flex justify-content-center">
        <img v-if="business.logo!=null" class="rounded-circle" style="height: 90px; width: 90px;" :src="business.logo"  >
        <img v-if="business.logo==null" class="rounded-circle" style="height: 90px; width: 90px;" :src="$img_placeholder"  >
    </div>
   
    <div class="d-flex justify-content-center">
        <p class="mb-0">{{business.name}}</p>
    </div>
    <div class="d-flex justify-content-center mb-2" style="height: 10px;">
        <small class="text-muted">
            <StarRating  :rating="business.ratings" :showCount="false"></StarRating>
        </small>
    </div>
   <div class="d-flex justify-content-center">
       <a href="#!" class="btn btn-link px-0">{{business.review_counter}} Reviews</a>
   </div>
  </div>
</template>

<script>

import StarRating from "./StarRating.vue";
export default {
  name:"EntityRoundedComponent",
  components:{
    StarRating,
  },
  props:{
    business:{
        type:Object,
        default:()=>{}
    }
  },
  data(){
    return{
        root_img_url:process.env.VUE_APP_IMG_URL, 
        backend_url: process.env.VUE_APP_BACKEND_URL,
    }
  },
  computed:{},
  methods: {},

}
</script>

<style lang="css" scoped>
 .rounded {
    border-radius: 15px;
 }
</style>
