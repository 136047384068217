<template>
    <div style="width: 100%;">
      <div class="d-flex justify-content-center" style="height:150px">
          <img v-if="business.logo!=null" class="custom-rounded-img"  style="height: 100%; width: 100%;" :src="business.logo"  >
          <img v-if="business.logo==null" class="custom-rounded-img" style="height: 100%; width: 100%;" :src="$img_placeholder"  >
      </div>
     
      <div class="d-flex justify-content-center">
          <h4 class="mb-0">{{business.name}}</h4>
      </div>
      <div class="d-flex justify-content-center mb-2" style="height: 10px;">
          <small class="text-muted">
            {{rating}} Stars 
          </small>
      </div>
     <!-- <div class="d-flex justify-content-center">
         <a href="#!" class="btn btn-link px-0">{{business.review_counter}} Reviews</a>
     </div> -->
    </div>
  </template>
  
  <script>
  
  // import StarRating from "./StarRating.vue";
  export default {
    name:"EntityProfileCard",
    components:{
      // StarRating,
    },
    props:{
      business:{
          type:Object,
          default:()=>{}
      }
    },
    data(){
      return{
          root_img_url:process.env.VUE_APP_IMG_URL, 
          backend_url: process.env.VUE_APP_BACKEND_URL,
      }
    },
    computed:{
      rating:function(){
         return this.business.ratings<0? 0:this.business.ratings
      }
    },
    methods: {},
  
  }
  </script>
  
  <style lang="css" scoped>
   .rounded {
      border-radius: 15px;
   }
  </style>
  