let searchMixins={

    methods:{

        handleSearchOnQueryChange(){
            let {query} = this.$route
            let queryKeys = Object.keys(query);
            if(query.entity_page){
               this.entity_page=parseInt(query.entity_page)
            }
            if(query.product_page){
              this.product_page=parseInt(query.product_page)
            }
             let currentEntityQueryString= this.getEntityQueryString(queryKeys,query)
             let currentProductQueryString = this.getProductQueryString(queryKeys,query)

             if(this.previous_entity_query_string!==currentEntityQueryString||(currentProductQueryString.length==0 && currentEntityQueryString.length==0)||!this.user_trigged_search){
                this.triggerEntitySearch(currentEntityQueryString);
             }
             if(this.previous_product_query_string!==currentProductQueryString||(currentProductQueryString.length==0 && currentEntityQueryString.length==0)||!this.user_trigged_search){
                this.triggerProductSearch(currentProductQueryString);
             }

             this.previous_entity_query_string=currentEntityQueryString;
             this.previous_product_query_string=currentProductQueryString;
           
        },
        getProductQueryString(keys,query){
              let queryString=''
               keys.forEach( (el) =>{
                   if(!el.includes('entity')){
                          let formated_key=el
                        if(el=='category__name'){
                            formated_key='parent_category__name'
                        }else if(el=='product_page'){
                            formated_key='page'
                        }
                        queryString += `${formated_key}=${query[el]}&`
                   }
                   
               })
               return queryString
        },
        getEntityQueryString(keys,query){
            let queryString=''
            keys.forEach( (el) =>{
                let formated_key=el
                if(el=='entity_page'){
                  formated_key='page'
                }
               queryString += `${formated_key}=${query[el]}&`
           })
           return queryString
        },

        changeUrl:function(searchData){
            let category_name = '';
            if (searchData.category?.uuid !== 'ALL') {
                category_name = searchData.category?.name;
            }
            this.$router.replace(
                 {
                     'name':'Home',
                     query:{
                         entity_page:1,
                         category__name:category_name,
                         name:searchData.search_text,
                         product_page: 1
                     }
                 }
             )
        },

        triggerSearchBySort(sortCondition){
            this.$router.replace(
                {
                    'name':'Home',
                    query:{
                        ...this.$route.query,
                        sortBy: sortCondition
                    }
                }
            )   
        },

        triggerSearchBySortOrder(orderBy){
            this.$router.replace(
                {
                    'name':'Home',
                    query:{
                        ...this.$route.query,
                        sortOrder: orderBy
                    }
                }
            )   
        },

        triggerSearchByCategory(category){
            let category_name=''
            if(category.uuid!=="ALL"){
               category_name=category.name
            }
            this.$router.replace(
                {
                    'name':'Home',
                    query:{
                        ...this.$route.query,
                        category__name:category_name,
                        entity_page:1,
                        product_page: 1
                    }
                }
            )
        },

        //entity pagination handler
        previousEntityPage:function(){
            this.user_trigged_search=true
            this.$router.replace(
                {
                    'name':'Home',
                    query:{
                        ...this.$route.query,
                        entity_page:this.entity_page-1,
                    }
                }
            ) 
        },
        nextEntityPage:function(){
            this.user_trigged_search=true
            this.$router.replace(
                {
                    'name':'Home',
                    query:{
                        ...this.$route.query,
                        entity_page:this.entity_page+1,
                        
                    }
                }
            ) 
        },

        //product pagination handler
        previousProductPage(){
          
                this.user_trigged_search=true
                this.$router.push(
                    {
                        'name':"Home",
                        query:{
                            ...this.$route.query,
                            product_page:this.product_page-1,
                        }
                    }
                ) 
               
        },
        nextProductPage(){
            this.user_trigged_search=true
            this.$router.push(
                {
                    'name':"Home",
                    query:{
                        ...this.$route.query,
                        product_page:this.product_page+1,
                        
                    }
                }
            )
           
        }
    },

};

export default searchMixins