<template>
    <div class="w-100 mt-7">

        <div class="container-fluid">

            <div class="row d-flex justify-content-center">
                <div class="col-md-8">
                    <HomeSearchField :categories="categories" @category-click="triggerSearchByCategory" @onsearch="triggerSearch" />
                </div>
            </div>

            <div class="row d-flex justify-content-center mt-1" id="allbusiness" v-if="businesses?.length > 0">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-12" v-if="current_category === 'ALL'">
                            <h3 v-if="$i18n.locale === 'kin'"> Abacuruzi bo mu byiciro byose</h3>
                            <h3 v-else>Business from All categories</h3>
                            <hr class="m-1" />
                        </div>
                        <div class="col-md-12" v-else>
                            <h3 v-if="$i18n.locale === 'kin'">{{ s_category.kin_name }}</h3>
                            <h3 v-else>Business from {{ current_category }}</h3>
                            <hr />
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-2" v-for="business in businesses" :key="business.uuid"
                                    @click="$router.push({ name: 'Company Profile', params: { slug: business.slug } })"
                                    style="cursor:pointer;width: 50%;">
                                    <EntityRoundedDisplay :business="business"></EntityRoundedDisplay>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 d-flex justify-content-end m--2"
                            v-if="paginated_entities.previous || paginated_entities.next">
                            <button class="btn btn-sm btn-white" :disabled="paginated_entities.previous == null"
                                @click="previousEntityPage()">Previous
                            </button>

                            <button class="btn btn-sm btn-white" disabled="true">
                                {{ paginated_entities.results?.length }}/{{ paginated_entities.count }}
                            </button>
                            <button class="btn btn-sm btn-white" :disabled="paginated_entities.next == null"
                                @click="nextEntityPage()">Next
                            </button>
                        </div>

                    </div>
                </div>
            </div><!--all companie or by category-->

            <div class="row d-flex justify-content-center">
                <div class="col-md-12">
                    <div class="row">
                      
                        <div class="col-md-8">
                            <div class="row mt-1 " ref="products" v-if="waiting_products">
                                <div class="col-md-12" v-if="current_category === 'ALL'">
                                    <h3 v-if="$i18n.locale === 'kin'">Ibicuruzwa byo mu byiciro byose</h3>
                                    <h3 v-else>Products from All categories</h3>
                                    <hr />
                                </div>
                                <div class="col-md-3 m-0" v-for="index in 4" :key="index">
                                    <LoadingCard />
                                </div>
                            </div>
                            <div class="row mt-3 " id="products" ref="products" v-if="products.length > 0">
                                <div class="col-md-12" v-if="current_category === 'ALL'">
                                    <h3 v-if="$i18n.locale === 'kin'">Ibicuruzwa byo mu byiciro byose</h3>
                                    <h3 v-else>Products from All categories</h3>
                                    <hr class="m-2" />
                                </div>
                                <div class="col-md-12" v-else>
                                    <h3 v-if="$i18n.locale === 'kin'">{{ s_category.kin_name }}</h3>
                                    <h3 v-else>Products from {{ current_category }}</h3>
                                    <hr class="m-3" />
                                </div>
                                <div  class="col-md-12 d-flex justify-content-end" >
                                    <div class="d-flex justify-content-end">
                                        <select v-model="sortCondition" class="sort-input">
                                            <option value="doneAt">Done At</option>
                                            <option value="is_special">Is Special</option>
                                            <option value="price">Price</option>
                                            <option value="ratings">Rate</option>
                                            <option value="review_counter">Review Counter</option>
                                        </select>
                                        <select v-model="sortOrder" class=" sort-input sort-order mr-2">
                                            <option value="asc">Asc</option>
                                            <option value="desc">Desc</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-md-3 p-2" v-for="product in products" :key="product.uuid"
                                    style="cursor:pointer;"
                                    @click="$router.push({ name: 'Product Details', params: { slug: product.slug } })">
                                    <ProductCard
                                            v-if="$displayManager.isMobile()"
                                            picture_size="mobile-display-size"
                                            :product="product"
                                    ></ProductCard>
                                </div>
                                <div class="col-md-12 d-flex justify-content-end"
                                    v-if="paginated_products.previous || paginated_products.next">
                                    <button class="btn btn-sm btn-white" :disabled="paginated_products.previous == null"
                                        @click="previousProductPage()">Previous
                                    </button>

                                    <button class="btn btn-sm btn-white" disabled="true">
                                        {{ paginated_products.results?.length }}/{{ paginated_products.count }}
                                    </button>
                                    <button class="btn btn-sm btn-white" :disabled="paginated_products.next == null"
                                        @click="nextProductPage()">Next
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="col-md-12 mt-5">
                                <Carousel class="m-1"
                                    :slides="slides.length == 0 ? dumy_data?.default_main_slides : slides" />
                            </div>
                            <!-- <div class="col-md-12 mt-3">
                                <h3 v-if="$i18n.locale === 'kin'"> Ibicuruzwa Bidasanzwe </h3>
                                <h3 v-else>Special Products</h3>
                                <hr>
                            </div>
                            <div class="col-md-12">
                                <ProductCarousel :slides="specialProducts" v-if="specialProducts.length > 0" />
                                <div class="card bg-dark text-white border-0" v-if="specialProducts.length === 0">
                                    <img class="card-img img-rounded" src="@/assets/images/hcplqyldvm3wmdunanbfcopy.jpg"
                                        style="height:300px">
                                    <div class="card-img-overlay d-flex align-items-center">
                                        <div>
                                            <h5 class="h2 card-title mb-2 text-black">SPECIAL PRODUCT TO DAY</h5>
                                            <div class="text-dark"> No Special Product today</div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-md-12 mt-3">
                                <h2 class="text black">{{ $t('contact-us') }}</h2>
                                <ul class="card shadow p-2" style=" list-style-type: none;">
                                    <li class="">
                                        <a href="tel:+250787836638" class="text-muted text-gray">+250787836638</a>
                                    </li>
                                    <li>
                                        <a href="mailto: collinsmwai@gmail.com"
                                            class="text-muted text-gray">collinsmwai@gmail.com</a>
                                    </li>
                                    <li class="text-muted text-gray">
                                        <span>Kigali</span>
                                        <span>, Rwanda</span>
                                    </li>

                                </ul>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

import axios from "axios";
// import ProductCarousel from "../../components/ProductCarousel";
import Carousel from "../../components/Carousel";
import EntityRoundedDisplay from "../../components/EntityRoundedDisplay.vue";
import json from "../../data/db.json"
import ProductCard from "@/components/ProductCard";
import HomeSearchField from "@/components/HomeSearchField";
import LoadingCard from "@/components/LoadingComponent/LoadingCard";
import searchMixins from "../../mixins/search_mixins";
// import CategorySidebar from "@/components/CategorySidebar";

export default {
    name: "Home",
    components: {
        LoadingCard,
        HomeSearchField,
        ProductCard,
        // ProductCarousel,
        Carousel,
        EntityRoundedDisplay,
        // CategorySidebar
    },
    mixins: [searchMixins],
    data() {
        return {
            dumy_data: json,
            backend_url: process.env.VUE_APP_BACKEND_URL,
            categories: [],
            products: {},
            current_category: "ALL",
            root_img_url: process.env.VUE_APP_IMG_URL,
            custom_search: "",
            search: "",
            s_category: "",
            toprated: [],
            specialProducts: [],
            slides: [],
            paginated_entities: {},
            paginated_products: {},

            entity_url: `${process.env.VUE_APP_BACKEND_URL}/api/entities/paginated/entity_search`,
            product_url: `${process.env.VUE_APP_BACKEND_URL}/api/products/all/search`,
            about: {},
            waiting_products: true,
            businesses: [],
            screen_type: '',

            //  Search  data 
            product_page: 1,
            entity_page: 1,
            search_params: {
                parent_category_name: "",
                name: "",
                page: "",
            },

            previous_entity_query_string: '',
            previous_product_query_string: '',
            user_trigged_search: false,
            restart_pagination: false,
            sortCondition:'doneAt',
            sortOrder: 'asc'

        }
    },
    watch: {
        '$route'() {
            this.handleSearchOnQueryChange()
        },
        products: function () {
            this.scrollToProduct()
        },
        sortCondition:function(){
            this.triggerSearchBySort(this.sortCondition)
        },
        sortOrder: function(){
          this.triggerSearchBySortOrder(this.sortOrder)
        }
    },

    methods: {

        triggerEntitySearch: function (queryString) {
            this.fetch_industries(this.entity_url + '?' + queryString);
        },

        triggerProductSearch(queryString) {
            this.searchProduct(this.product_url + '?' + queryString);
        },

        triggerSearch: function (evt) {
            this.user_trigged_search = true
            this.changeUrl(evt);
            this.current_category = this.$i18n.locale === 'kin' ? evt.category.kin_name : evt.category.name
            this.s_category = evt.category
        },

        fetch_industries: function (url) {
            axios
                .get(
                    url + this.category_params
                )
                .then((res) => {
                    res.data.results.sort(function () {
                        return 0.5 - Math.random();
                    });
                    this.paginated_entities = res.data;
                    this.businesses = res.data.results
                })
                .catch(() => {
                });
        },

        fetch_toprated_business: function () {
            axios
                .get(
                    `${process.env.VUE_APP_BACKEND_URL}/api/entities/view/toprated/`
                )
                .then((res) => {
                    this.toprated = res.data
                })
                .catch(() => {
                });
        },
        fetch_categories: function () {
            axios
                .get(`${process.env.VUE_APP_BACKEND_URL}/api/parentCategories`)
                .then((res) => {
                    this.categories = res.data
                })
                .catch(() => {
                });
        },

        searchProduct: function (path) {
            axios
                .get(path)
                .then((res) => {
                    this.paginated_products = res.data;
                    this.products = res.data.results;
                    this.waiting_products = false

                })
                .catch(() => {
                });
        },

        getSpecialProduct: function () {
            axios
                .get(`${process.env.VUE_APP_BACKEND_URL}/api/products/show/special/`)
                .then((res) => {
                    this.specialProducts = res.data
                })
                .catch(() => {
                });
        },

        fetch_adverts: function () {
            var url = `${process.env.VUE_APP_BACKEND_URL}/api/posts/public/`;
            axios
                .get(url)
                .then((res) => {
                    this.slides = res.data
                })
                .catch(() => {
                });
        },
        scrollToProduct: function () {
            const container = document.getElementById('products');
            if (container !== null) {
                container.scrollIntoView({ behavior: 'smooth' });
            }
        }



    },

    created() {
        document.title = "BCM-Publish your work"
        this.handleSearchOnQueryChange()
        this.fetch_categories()
        // this.fetch_toprated_business()
        this.getSpecialProduct()
        this.fetch_adverts()


    },
};
</script>