<template>
    <div class="category-sidebar-container p-0 mb-3">
      <div class="">
        <div>
              <tr class="category-sidebar-item"  v-for="category in categories"  :key="category.name" @click="selectCategory(category)">
                <td :style="'width: 10%;color:'+category.color"  ><span :class="category.icon"></span></td>
                <td style="width: 90%; margin-left: 12px;">
                  <span class="category-style">
                    {{ category.name }} 
                  </span> 
                </td>
              </tr>
        </div>
      </div>  
    </div>
</template>
    
<script>
    export default {
      name:"CategorySidebar",  
      props:{
          categories:{
              type: Object,
              default: () => {},
          },
          oldSelected:{
            type: Array,
            required: false,
            default:()=>[]
          }
      },
      data(){
        return {
          resultCategories:[],
          selected:[],
          showCategories:false
        }
      },

      methods:{
        selectCategory:function(category){
           this.$emit('category-click',category)
        }
      },
     
    };
</script>
      
<style>
 .category-style{
        font-size: 12px;
        font-weight: 10;
  }
 .category-container{
    cursor: pointer;
 }  


  .category-sidebar-container{
      width: 100%;
      height: 100%;
  }


    .category-sidebar-item{
        width: 100%;
        height: 100%;
        padding: 20px;
        margin: 10px;
        cursor: pointer;
    }

    .category-sidebar-item>td{
       border: #d3d3d3 solid 1px;
       border-collapse: collapse;
       padding: 8px;
       display: table-cell;
    }
</style>