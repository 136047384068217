<template>
    <div class="w-100 mt-7">
       <template  v-if="$displayManager.isMobile()" >
        <mobile-display></mobile-display>
       </template>
       <template v-else>
         <pc-display></pc-display>
       </template>
    </div>
</template>

<script>


import MobileDisplay from "./Home/MobileDisplay.vue"
import PCDisplay from "./Home/PCDisplay.vue"
    export default {
        name: "Home",
        components: {
          'mobile-display':MobileDisplay,
          'pc-display':PCDisplay
        },
        data() {
            return {
            }
        },

        watch: {
        },
       
        methods: { 
        },

        created() {
        },
    };
</script>