<template>
   <div class="loading-card mt-2">
     <div class="card__image"></div>
     <div class="card__content">
       <h2></h2>
       <p class="button-emitter"></p>
     </div>
   </div>
</template>

<script>
export default {
  name: "LoadingCard"
}
</script>

<style scoped>
.loading-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.card__image,
h2,
.button-emitter {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}

.card__image {
  height: 200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.card__content {
  padding: 20px;
}

h2 {
  height: 30px;
}

.button-emitter {
  height: 40px;
  width: 60%;
}
</style>